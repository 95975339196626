.tableHeadBuyer {
  height: 30px;
  border-radius: 7px;
  th {
    font-weight: 400;
    font-size: 10px !important;
    color: #4c4c4c;
    padding: 0 !important;
  }
}
.tableContainerBuyer {
  background: transparent;
  box-shadow: none !important;
}