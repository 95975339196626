.PaymentModule {
  &__button {
    background: #176318;
    border-radius: 6px;
    font-family: Archivo Black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
    height: 30px;

    &:disabled {
      opacity: .5;
    }
  }

  &__modal {
    h4 {
      margin: 0px;

    }
    &__data {
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden  ;
      gap: 3px;
      display: flex;
      flex-direction: column;

      hr {
        width: 100%;
        color: #bdbdbd;
        margin: 0;
      }
      
      &__item {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        color: #252525;
        font-weight: 400;
        font-size: 14px;

        button:disabled {
          opacity: .5;
        }

        &__number {
          width: 10px;
        }

        &__description {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 12px;
              flex: 1;
            }
            span:nth-child(2) {
              text-align: right;
            }
          }
        }
      }
    }
    &__bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      div {
        font-weight: 600;
      }
      button:disabled {
        opacity: .7;
      }
    }

    &__addPayment {
      h4 {
        margin: 0px;
      }
      
      &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          color:#252525;
          margin: 0px;
        }

        input {
          outline: none;
          border: 1px solid #176318;
          border-radius: 6px;
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          padding: 10px 5px;
        }
        select {
          outline: none;
          border: 1px solid #176318;
          border-radius: 6px;
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          padding: 10px 5px;
        }
      }
    }
  }
}