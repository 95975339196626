.ProductManagerFilter {
  &__place {
    display: flex;
    gap: 5px;

    &__buttonsContainer {
      border-radius: 8px;
      display: flex;
      overflow: hidden;
      border: 1px solid #2e7d32;
      flex-wrap: wrap;

      button {
        border: none;
        outline: none;
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;

        &:disabled {
          opacity: 0.8;
        }
      }
    }
  }
}