.headerAgr {
  background-color: #5fc56e;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between; /* Distribute space between header items */
  width: 100%; /* Header spans full width */
  box-sizing: border-box; /* Include padding in element width */
}

.contentAgr {
  padding: 10px;
  width: 100%; /* Content spans full width */
  box-sizing: border-box; /* Include padding in element width */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  /* Adjust breakpoint as needed */
  .headerAgr {
    padding: 0; /* Stack header items vertically on smaller screens */
    font-size: 16px;
    max-width: 300px;
  }

  .contentAgr {
    padding: 0;
    font-size: 14px;
    max-width: 300px;
    h3 {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  }
}


.AggrupacionRadioFilter {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  height: 30px;
  width: fit-content;

  button {
    border-radius: 0;
    max-width: 30px;
    min-width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;

    &:disabled {
      opacity: 0.5;
    }
  }
}