.BuyerSelectModal {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 40px;
  z-index: 100;

  border: 1px solid #d9d9d9;
  border-radius: 10px 0 0 10px;
  width: 81px;
  height: fit-content;
  background: #fff;

  button {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;

    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #176318;
    p {
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: #4c4c4c;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}