.MBAppPage {
  width: 100vw;
  height: 110vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  img {
    width: 100vw;
  }

  &__zoom {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    overflow: hidden;

    button {
      width: 40px;
      height: 50px;
      outline: none;
      border: none;

      color: #252525;
      font-size: 30px;
      font-weight: 300;
    }
  }

  &__coming {
    width: 100vw;
    height: 120vh;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background: #25252551;

    &__modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 300px;
      height: 100px;

      font-size: 30px;
      font-weight: 600;
      color: #176318;
      text-align: center;
      border-radius: 10px;
      border: 2px solid #176318;
      background-color: #fff;

      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;

      span {
        font-size: 20px;
        opacity: 0.7;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .MBAppPage {
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 1080;
      width: auto;
    }
  }
}