.tableRowBuyerMobile {
  height: 30px !important;
  
  td:first-child { border-top-left-radius: 10px; }
  td:first-child { border-bottom-left-radius: 10px; }
  td:last-child { border-top-right-radius: 10px; }
  td:last-child { border-bottom-right-radius: 10px; }
  td {
    height: 30px !important;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    color: #4c4c4c;
    padding: 0 2px !important;
  }
  outline: none;
}

.memoizedPavilionRow {
  outline: none;
  th,td {
    font-weight: 400;
    font-size: 10px;
    color: #4c4c4c;
  }
}
.memoizedPavilionRowBtn {
  height: 20px !important;
}
